// out:false
@mixin Width {
  &-100 {
    width: 100%;
  }

  &-50 {
    width: 50%;
  }

  &-75 {
    width: 75%;
  }

  &-auto {
    width: auto !important;
  }
}

.w {
  @include AddMedia {
    @include Width;
  }

}