// out: false, compress: true

/*=====================================MainReset================================================*/

input,
textarea {
    box-shadow: none;
}

input[type=text]::-ms-clear {
    display: none;
    width  : 0;
    height : 0;
}

input[type=text]::-ms-reveal {
    display: none;
    width  : 0;
    height : 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

q {
    quotes: "\201c""\201d";
}

body p:empty,
body h1:empty,
body h2:empty,
body h3:empty,
body h4:empty,
body h5:empty,
body h6:empty,
body li:empty {
    display: none;
}

* {
    color: inherit;
}


html {
    font-size: 16px;
}

html *,
body *,
html *::after,
body *::after,
html *::before,
body *::before {
    margin       : 0;
    padding      : 0;
    border       : none;
    border-radius: 0;
    box-sizing   : border-box !important;
    outline      : none;
}

sup {
    font-size: 0.6em;
}




input,
textarea {
    border: 1px solid rgb(121, 133, 141);

    font-weight: 400;
}



label,
button,
select,
input[type=submit] {
    cursor   : pointer;
    font-size: inherit;
}

button {
    background: transparent;
}

textarea {
    resize  : none;
    overflow: auto;
}

a {
    text-decoration: none;
    color          : inherit;
}

img[src=""],
img[src="/"] {
    display: none;
}

img {
    max-width  : 100%;
    object-fit : scale-down;
    display    : block;
    user-select: none;
}

ul li {
    list-style: none;
}

ol li {
    list-style: decimal;
}

hr {
    width     : 100%;
    height    : 1px;
    background: rgba(0, 0, 0, 0.1);
}


dialog {
    display : block;
    position: absolute;
    left    : 0px;
    right   : 0px;
    width   : auto;
    height  : auto;
    color   : none;
    margin  : 0;
    border  : none;

    background: none;
}

dialog::backdrop {
    background: none;
    display   : none;
}

picture {
    position: static !important;
}