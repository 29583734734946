// out:false




.image {
	overflow: hidden;
	display : block;
	position: relative;

	img {
		width     : 100%;
		height    : 100%;
		object-fit: cover;

		position      : relative;
		z-index       : 1;
		user-select   : none;
		pointer-events: none;
	}


	&--box,
	&--rect {
		&:before {
			content: '';
			display: block;
			width  : 100%;
		}

		img {
			position: absolute;
			left    : 0;
			top     : 0;


		}
	}

	&--box {
		&:before {
			padding-bottom: 100%;
		}


	}


	&--rect {
		&:before {
			padding-bottom: calc(100% * 3 / 4);
		}


	}

	&--cover {
		img {
			object-fit: cover;
		}
	}

	&--contain {
		img {
			object-fit: contain;
		}
	}

	&--scale-down {
		img {
			object-fit: scale-down;
		}
	}

	&--round {
		border-radius: 50%;
	}

	&-action {
		transition: .3s;

		&:after {
			position  : absolute;
			content   : '';
			z-index   : 10;
			width     : 200%;
			height    : 100%;
			top       : -90%;
			left      : -20px;
			opacity   : .1;
			transform : rotate(45deg);
			background: linear-gradient(to top, transparent, #fff 15%, rgba(#fff, .5));
			transition: .3s;
			pointer-events: none;
		}

		img {
			transition: .3s;

		}

		@media(hover) {
			&:hover {
				// box-shadow: 0 8px 16px 3px rgba(#000, .6);


				&::after {
					transform: rotate(25deg);
					top      : -40%;
					opacity  : .25;
				}

				img {
					transform: translateY(-2px) scale(1.2) rotateX(15deg);
				}
			}
		}
	}
}