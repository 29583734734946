// out:false

.grid.swiper .swiper-wrapper {
  flex-wrap      : nowrap;
  /* width       : 100%; */
  /* min-width   : 0; */
  /* margin-left : 0; */
  /* margin-right: 0; */
}

.swiper {
  user-select: none;
}

img.swiper-lazy {
  transition: .2s ease;
}

img.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
}

.swiper-button-disabled {
  opacity       : .4;
  pointer-events: none;
}

.swiper-slide {
  height    : auto;
  align-self: stretch;
}

.slider {
  position: relative;
}


.slider-prev,
.slider-next {
  position : absolute !important;
  top      : calc(50% - var(--size) / 2);
  z-index  : 3;
  $arrowLg1: $lg + (2 * (15px + 60px - 24px));

}


.slider-next {
  left    : calc(100% + 24px);
  $arrowLg: $lg + (2 * (24px + 60px + 24px));


  @media(max-width:$arrowLg) {
    left: calc(100% - 24px);
  }

  @media(max-width:$md) {
    left : auto;
    right: 2px;
  }
}


.slider-prev {
  right   : calc(100% + 24px);
  $arrowLg: $lg + (2 * (24px + 60px + 24px));

  @media(max-width:$arrowLg) {
    right: calc(100% - 24px);
  }

  @media(max-width:$md) {
    right: auto;
    left : 2px;
  }
}

.slider--overwrap {
  @media(max-width:$md) {}

  &>.swiper {
    @media(max-width:$md) {
      width        : calc(100% + 30px);
      margin-left  : -15px;
      margin-right : -15px;
      padding-right: 15px;
      padding-left : 15px;
    }
  }
}