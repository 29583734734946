@function rgbValuesFromHex($hex) {
  @return red($hex),
  green($hex),
  blue($hex);
}

@mixin getColors($color, $name) {
  $rgb                   : rgbValuesFromHex($color);
  $lighten               : lighten($color, 5%);
  $darken                : darken($color, 5%);
  --color-#{$name}       : #{$color};
  --color-#{$name}-10    : rgba(#{$rgb}, 0.1);
  --color-#{$name}-20    : rgba(#{$rgb}, 0.20);
  --color-#{$name}-30    : rgba(#{$rgb}, 0.30);
  --color-#{$name}-40    : rgba(#{$rgb}, 0.40);
  --color-#{$name}-50    : rgba(#{$rgb}, 0.50);
  --color-#{$name}-60    : rgba(#{$rgb}, 0.60);
  --color-#{$name}-70    : rgba(#{$rgb}, 0.70);
  --color-#{$name}-80    : rgba(#{$rgb}, 0.80);
  --color-#{$name}-90    : rgba(#{$rgb}, 0.90);
  --color-#{$name}--light: #{$lighten};
  --color-#{$name}--dark : #{$darken};
}


@mixin AddMedia() {
  @content;

  &-sm {
    @media (min-width:$sm) {
      @content;
    }
  }

  &-md {
    @media (min-width:$md) {
      @content;
    }
  }

  &-lg {
    @media (min-width:$lg) {
      @content;
    }
  }
}